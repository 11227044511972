html, body {
  height:100%;
}

.description {padding-bottom: 100%;}

.slider {
  .slides {
    .caption {
    }
  }
}

.calendar-table {
  overflow-y: scroll;
}

.l-fullHeight {
  min-height:100%;
}
// .m-fullHeight, .s-fullHeight {
//   min-height:0;
//   min-height:unset;
// }
/* Custom Stylesheet */
/**
 * Use this file to override Materialize files so you can update
 * the core Materialize files in the future
 *
 * Made By MaterializeCSS.com
 */


/* PastelViolett RAL-4009 */ 
  .pc-bg {
    background-color: $primary-color;
  }
  .pc-text {
    color: $primary-color;
  }
  .sc-bg {
    background-color: $secondary-color;
  }
  .sc-text {
    color: $secondary-color;
  }
  
  
  nav ul a,
  nav .brand-logo {
    color: white;
  }
  
  p {
    line-height: 2rem;
  }
  
  .sidenav-trigger {
    color: $pc-con;
  }

  .parallax-container {
    min-height: 100%;
    line-height: 0;
    color: rgba(255,255,255,.9);
  }
  .parallax-container .section {
    width: 100%;
  }


  
  @media only screen and (max-width : 992px) {
    .m-fullHeight {
      min-height:100%;
    }
    .parallax-container .section {
      position: absolute;
      top: 40%;
    }
    #index-banner .section {
      top: 10%;
    }
  }
  
  @media only screen and (max-width : 600px) {
    .s-fullHeight {
      min-height:100%;
    }
    #index-banner .section {
      top: 0;
    }

    p {
      text-align: justify;
      font-size: 14px; 
      line-height: 1.5rem;
    }

  }


  
  .icon-block {
    padding: 0 15px;
  }
  .icon-block .material-icons {
    font-size: inherit;
  }
  
  footer.page-footer {
    margin: 0;
  }

  .mylist {
      li {
        list-style: disc !important;
        font-size: 14px;
        
      }
  }

  #googlemap {
    height: 400px;
    width:100%;
  }


#umgebung {
    margin-bottom: 0px;
  }
  
ul.list {
  li {
    padding: 5px 0px;
  }
}

.materialboxed {
 max-height: 500px;
 max-width:100%;
}